<template>

  <div class="order-catering-child-bg">
    <img class="order-catering-child-bg-gif" src="./Catering-child-page-image/Order-catering-child-bg-gif.gif" alt="">
    <img src="./Catering-child-page-image/Catering-child-bg.png" alt="">
    <div class="order-catering-child-bg-component">
      <div class="img-one"></div>
      <a href="#order-catering-child">
        <div class="img-two"></div>
      </a>
      <div class="img-right-side">
        <div class="img-three">
          <img src="./Catering-child-page-image/Catering-child-component-three.png" alt="">
        </div>
        <div class="img-four"></div>
        <div class="img-five"></div>
        <div class="img-six"></div>
        <div class="img-seven"></div>
      </div>
    </div>
  </div>

  <div class="order-catering-child" id="order-catering-child">
    <div class="order-catering-child-header">
      <h1>Catering for all occasions</h1>
      <p>Experience the Fresh Taste At A Great Price, Exclusively Available For Those Who Love Delicious Meals And Are
        Always Hungry For More!</p>
    </div>
    <div class="order-caterings-card  ">

      <div class="order-catering-card-compo position-relative d-flex align-items-center "
           v-for="(product, index) in parsedProduct" :key="product">
        <div class="order-catering-card-left">
          <img :src="product.image" alt="">
        </div>
        <div class="order-catering-card-right">
          <h1>{{ product.title }}</h1>

          <div class="d-flex flex-column  justify-content-center align-items-start">
            <!--            <a href="" class="order-catering-btn">Order Now</a>-->

            <a href="javascript:void(0)" class="order-read-more" @click="toggleReadMore(index)">
              {{ product.isVisible ? 'Show Less' : 'Read More' }}
            </a>
            <h6 v-if="product.isVisible" style="font-weight: 400; width: 85%;">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the
              industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged.</h6>
          </div>
        </div>
      </div>

    </div>
  </div>


  <div class="confirm-order">
    <div class="cofirm-order-head">
      <h1>Confirm Your Order</h1>
      <p>Just review your choices, check the details, and click 'Submit' to finalize. We'll handle the rest!</p>
    </div>
    <form @submit.prevent="handleSubmit">
      <div class="confirm-order-input">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
          <path
              d="M12.8004 10.8C15.2304 10.8 17.2004 8.83005 17.2004 6.4C17.2004 3.96995 15.2304 2 12.8004 2C10.3703 2 8.40039 3.96995 8.40039 6.4C8.40039 8.83005 10.3703 10.8 12.8004 10.8Z"
              fill="#691209"/>
          <path
              d="M21.6 19.0501C21.6 21.7836 21.6 24.0001 12.8 24.0001C4 24.0001 4 21.7836 4 19.0501C4 16.3166 7.9402 14.1001 12.8 14.1001C17.6598 14.1001 21.6 16.3166 21.6 19.0501Z"
              fill="#691209"/>
        </svg>
        <input type="text" placeholder="Full Name" v-model="name">
      </div>
      <p v-if="errors.name" class="error-message">{{ errors.name }}</p>
      <div class="confirm-order-input">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
          <g clip-path="url(#clip0_1269_1356)">
            <path
                d="M20.8422 8.48895L24.7204 12.0334C25.189 12.4677 25.0552 12.8026 24.4197 12.8026H21.5108V22.0632C21.5108 22.6987 20.9759 23.2668 20.3404 23.2668H15.2918V16.7803C15.2918 16.1448 14.7237 15.5767 14.0882 15.5767H10.9118C10.2763 15.5767 9.70823 16.1448 9.70823 16.7803V23.2668H4.65961C4.02412 23.2668 3.45603 22.6987 3.45603 22.0632V12.8026H0.580271C-0.0552193 12.8026 -0.18895 12.4677 0.279644 12.0334L11.6136 1.73606C12.0822 1.3017 12.8846 1.3017 13.3853 1.73606L15.9594 4.04265V2.20358C15.9594 1.56809 16.5275 1 17.1629 1H19.6707C20.3062 1 20.8411 1.56809 20.8411 2.20358L20.8422 8.48895Z"
                fill="#691209"/>
          </g>
          <defs>
            <clipPath id="clip0_1269_1356">
              <rect width="25" height="25" fill="white"/>
            </clipPath>
          </defs>
        </svg>
        <input type="text" placeholder="Address" v-model="address">
      </div>
      <p v-if="errors.address" class="error-message">{{ errors.address }}</p>
      <div class="confirm-order-input">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
          <path
              d="M21.8935 22.9999C21.8376 22.9999 21.7826 22.998 21.7267 22.9934C11.241 22.1813 2.76281 13.7077 2.00565 3.2834C1.98457 2.99485 2.02264 2.70503 2.11753 2.43171C2.21242 2.15839 2.36213 1.90733 2.55748 1.69391C2.74812 1.48555 2.9799 1.31899 3.23819 1.20476C3.49647 1.09053 3.77564 1.03111 4.05805 1.03025L8.36819 1C9.32518 1 10.1529 1.64258 10.3839 2.56199L11.2841 6.13788C11.384 6.53273 11.3642 6.94838 11.2273 7.33196C11.0903 7.71555 10.8424 8.04974 10.515 8.29203L8.78344 9.58178C10.3027 12.3479 12.572 14.6289 15.3302 16.1625L16.6685 14.3897C16.9136 14.0645 17.249 13.8187 17.633 13.683C18.0169 13.5474 18.4323 13.5279 18.8273 13.627L22.4279 14.5207C22.8763 14.6305 23.2749 14.8875 23.5599 15.2506C23.8449 15.6138 23.9999 16.062 24 16.5236V20.9365C24 21.5121 23.7672 22.0447 23.3437 22.437C22.9479 22.7998 22.4304 23.0007 21.8935 22.9999Z"
              fill="#691209"/>
        </svg>
        <input type="text" placeholder="Phone Number" v-model="phone">
      </div>
      <p v-if="errors.phone" class="error-message">{{ errors.phone }}</p>


      <input class="confirm-submit" type="submit" name="" id="" value="Submit">
      <div class="form-error" v-if="showError">{{ showError }}</div>
    </form>
  </div>


  <div class="catering-child-lifestyle">

    <div class="catering-child-lifestyle-left">
      <div class="slide-icon-left">

      </div>
      <div class="lifestyle-content">
        <h3>TOP IN THE WEEK</h3>
        <h1>
          Choose Your
          Healthy Lifestyle.
        </h1>
        <p>
          Presentation matters. Our fresh Vietnamese vegetable rolls look good and taste even better
        </p>
        <a href="" class="order-catering-btn">
          ORDER NOW
        </a>
      </div>
    </div>
    <div class="catering-child-lifestyle-right">
      <img src="./Catering-child-page-image/Catering-child-slid.png" alt="">
    </div>
  </div>

</template>

<script>

export default {
  props: ['products'],
  data() {
    return {
      parsedProduct: JSON.parse(this.products).map(product => ({
        ...product,
        isVisible: false // Add isVisible property to each product
      })),
      name: '',
      address: '',
      phone: '',
      errors: {},
      showError: ''
    };
  },
  mounted() {
    console.log(this.parsedProduct); // Print the parsedProduct array to the console
  },
  methods: {
    toggleReadMore(index) {
      this.parsedProduct[index].isVisible = !this.parsedProduct[index].isVisible;
    },
    validateForm() {
      this.errors = {};
      if (!this.name) {
        this.errors.name = 'Name is required.';
      }
      if (!this.address) {
        this.errors.address = 'Address is required.';
      }
      if (!this.phone) {
        this.errors.phone = 'Phone number is required.';
      } else if (!this.validPhone(this.phone)) {
        this.errors.phone = 'Invalid phone number.';
      }

      return Object.keys(this.errors).length === 0;
    },
    validPhone(phone) {
      const re = /^\d{10,15}$/;
      return re.test(phone);
    },
    async handleSubmit() {
      // Collect the form data
      const formData = new FormData();
      formData.append('full_name', this.name);
      formData.append('address', this.address);
      formData.append('phone_number', this.phone);
      try {
        const response = await fetch(window.location.href, {
          method: 'POST',
          headers: {
            'X-CSRFToken': this.getCookie('csrftoken')
          },
          body: formData
        });

        if (response.ok && this.validateForm()) {
          const data = await response.json();
          if (data.success) {
            // Redirect to another page on successful login
            this.showError = 'Your request placed! Our agent will contact you soon..';
            this.name = ""
            this.address = ""
            this.phone = ""
          } else {
            // Handle login failure (e.g., show error message)
            this.showError = 'Something went wrong.';
          }
        } else {
          this.showError = 'Something went wrong.';
        }
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    },
    getCookie(name) {
      const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
      return cookieValue ? cookieValue.pop() : '';
    },
  }

};
</script>

<style>
.order-catering-child-bg {
  position: relative;
  height: 800px;
}

.order-catering-child-bg-gif {
  position: absolute;
  width: 62% !important;
  height: 76% !important;
}

.order-catering-child-bg > img {
  width: 100%;
  height: 100%;
}

.order-catering-child-bg-component {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
}

.order-catering-child-bg-component .img-one::before {
  content: '';
  width: 261.558px;
  height: 261.558px;
  transform: rotate(0deg);
  background: url(./Catering-child-page-image/Catering-child-component-six.png);
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 11%;
  left: -3%;
  animation: chicken 5s infinite;
}

@keyframes chicken {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1rem);
  }
}


.order-catering-child-bg-component .img-two::before {
  content: '';
  width: 80.628px;
  height: 80px;
  background: url(./Catering-child-page-image/down-arrow.png);
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 80%;
  left: 44%;
  animation: downArrow 2s infinite;
}

@keyframes downArrow {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1rem);
  }
}

.img-right-side .img-three img {
  width: 600px;
  height: 600px;
  transform: translate(0%, 57%);
}


.order-catering-child {
  margin-top: 80px;
  height: fit-content;

}

.order-catering-child-header {
  width: 50%;
  text-align: center;
  margin: auto;
  margin-bottom: 80px;
}

.order-catering-child-header h1 {
  color: #691209;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 100% */
  letter-spacing: 1.44px;
  margin-bottom: 12px;
}

.order-catering-child-header p {
  color: #333;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 200% */
  margin: 0;
}

.order-caterings-card {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0 40px;
  gap: 25px;

}

.order-catering-card-compo {
  gap: 25px;
  border-radius: 20px;
  border: 1px solid #E7EAF3;
  background: #FFFDFD;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
  padding: 25px 25px 25px 0;
  overflow: hidden;
}

.order-catering-card-left img {
  left: -129px;
  position: relative;
  width: 250px;
  left: -129px;
  height: 250px;
}

.order-catering-card-compo .order-catering-card-left img,
.order-catering-card-compo .order-catering-card-right {
  transition: transform 0.3s ease; /* Adjust the duration and timing function as needed */
}

.order-catering-card-compo:hover .order-catering-card-left img {
  transform: translateX(48px); /* Adjust the value to control the amount of slide */
}

.order-catering-card-compo:hover .order-catering-card-right {
  transform: translateX(48px); /* Adjust the value to control the amount of slide */
}

.order-catering-card-left {
  width: 30%;
}

.order-catering-card-right {
  width: 70%;
}

.order-catering-card-right h1 {
  color: #691209;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 18px;
}

.catering-child-lifestyle-right p {
  color: #4A4A4A;
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 30px;
}

.order-catering-btn {
  border-radius: 50px;
  background: #691209;
  padding: 14px 20px;
  text-align: center;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  text-transform: capitalize;
  border: none;
  margin-bottom: 10px;
  text-decoration: none;
  display: inline-block;
}

.order-catering-card-right .order-read-more {
  color: #1E1E1E;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  text-transform: capitalize;
  padding: 14px 20px;
  text-decoration: none;
  border: 1px solid #691209;
  border-radius: 1.5rem;
}

.order-catering-card-right .order-read-more:hover {
  background: #691209;
  color: white;
  border: 1px solid transparent;
}

.catering-child-lifestyle {
  background: url(./Catering-child-page-image/Catering-child-bg-2.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 80px 0;
  height: 810px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 80px;
  padding: 80px;
}


.lifestyle-content h3 {
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px; /* 164.286% */
  text-transform: uppercase;
  margin-bottom: 30px;
}

.lifestyle-content h1 {
  color: #FFF;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 30px;
}

.lifestyle-content p {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
  margin-bottom: 30px;
}

.lifestyle-btn {
  padding: 12px 50px;
  border-radius: 100px;
  background: #691209;
  width: fit-content;
  color: #FFF;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px; /* 176.923% */
  text-transform: uppercase;
}


/* confirm form  */


.confirm-order {
  background: url(./Create-Item-child-page-image/Create-item-child-from-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 60px;
  width: 100%;
  height: 100vh;
  margin: 80px 0;
  background-position: center;

}

.cofirm-order-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 40px;
}

.cofirm-order-head h1 {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.cofirm-order-head p {
  color: var(--Text-color, #1E1E1E);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.42px;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

}

.confirm-order-input {
  display: flex;
  align-items: center;
  gap: 36px;
  padding: 14px 36px;
  border: 1px solid rgba(19, 33, 68, 0.10);
  background: #FFF;

}

.confirm-order-input input {
  width: 100%;
  color: #4A4A4A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
  letter-spacing: 0.42px;
  border: none;
  outline: none;
  padding: 10px;
}

.confirm-submit {
  margin-top: 30px;
  padding: 18px 0;
  width: 100%;
  border: 1px solid rgba(19, 33, 68, 0.10);
  background: #691209;
  box-shadow: 0px 0px 12px 0px rgba(204, 204, 204, 0.20);
  color: var(--Main-gohan, #FFF);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 100% */
  letter-spacing: 0.54px;
}


@media only screen and  (max-width: 599px) {
  .order-catering-child-header {
    width: 70%;
  }

  .order-catering-child {
    padding: 80px 20px;
  }

  .order-caterings-card {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }

  .order-catering-child-bg-gif {
    position: absolute;
    width: 100% !important;
    height: 76% !important;
  }

  .order-caterings-card-head {
    width: 250px;
  }

  .order-catering-child-details {
    margin-bottom: 10px;
  }

  .order-food-name-price h2 {
    font-size: 22px;
  }

  .order-caterings-card-body {
    padding: 20px 10px;
  }

  .catering-child-lifestyle {
    margin: 80px 0;
    align-items: center;
    gap: 0px;
    padding: 80px 20px;
    flex-direction: column-reverse;
  }

  .catering-child-lifestyle-right img {
    width: 380px;
  }

  .lifestyle-content h1 {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .img-right-side .img-three img {
    width: 250px;
    height: 297px;
    transform: translate(0%, 198%);
  }

  .order-catering-child-bg-component .img-one::before {
    content: '';
    width: 229.558px;
    top: 11%;
    left: -3%;

  }

  .order-catering-child-bg-component .img-two::before {
    content: '';
    width: 53.628px;
    height: 75px;
    top: 66%;
    left: 37%;

  }

  .order-catering-card-left {
    width: 23%;
  }

  .order-catering-card-right {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .order-catering-card-left img {
    left: -120px;
    position: relative;
    width: 200px;
    height: 200px;
  }

  .order-catering-card-right h1 {
    color: #691209;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 18px;
  }

  .order-catering-child {
    padding: 80px 0px;
  }

  .order-catering-child-header {
    width: 80%;
  }

  .order-caterings-card {

    padding: 0 20px;
  }

  .order-catering-card-compo {
    gap: 18px;
  }

  .order-catering-card-right h2 {
    padding: 10px 64px;
  }

  .catering-child-lifestyle-right img {
    width: 340px;
  }

  .confirm-order-input {
    gap: 0px;
  }

  .order-catering-child-header h1 {
    font-size: 2.5rem;
  }
  .order-catering-child-header p {
    text-align: start;
  }
  .confirm-order {
    padding: 2rem 3rem;
  }
}


@media only screen and (min-width: 600px) and (max-width: 991px) {
  .order-catering-child-header {
    width: 70%;
  }

  .order-catering-child {
    padding: 80px 40px;
  }

  .order-caterings-card {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }

  .order-caterings-card-head {
    width: 250px;
  }

  .order-catering-child-details {
    margin-bottom: 10px;
  }

  .order-food-name-price h2 {
    font-size: 22px;
  }

  .order-caterings-card-body {
    padding: 20px 10px;
  }

  .catering-child-lifestyle {
    margin: 80px 0;
    align-items: center;
    gap: 0px;
    padding: 80px 40px;
    flex-direction: column-reverse;
  }

  .catering-child-lifestyle-right img {
    width: 470px;
  }

  .lifestyle-content h1 {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .img-right-side .img-three img {
    width: 250px;
    height: 297px;
    transform: translate(0%, 198%);
  }

  .order-catering-child-bg-component .img-one::before {

    left: -5%;

  }

}


@media only screen and (min-width: 992px) and (max-width: 1299px) {

  .order-catering-child {
    padding: 80px 40px;
  }

  .order-caterings-card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
  }

  .order-caterings-card-head {
    width: 287px;
  }

  .catering-child-lifestyle {
    gap: 60px;
    padding: 80px 40px;
  }

  .life-content {

  }

  .lifestyle-content h1 {
    font-size: 35px;
  }

  .img-right-side .img-three img {
    width: 430px;
    height: 450px;
    transform: translate(0%, 106%);
  }

}


@media only screen and (min-width: 1300px) and (max-width: 1499px) {
  .order-caterings-card-head {

    width: 353px;
  }

  .img-right-side .img-three img {
    width: 475px;
    height: 529px;
    transform: translate(0%, 79%);
  }


}


.img-four::before {
  content: '';
  background: url(./Catering-child-page-image/Catering-child-component-nine.png);
  bottom: 40%;
  right: 33%;
  position: absolute;
  width: 73px;
  height: 67px;
  animation: tree 6s ease-in infinite;
}


@keyframes tree {
  0%, 100% {
    transform: translate(0);
  }
  50% {
    transform: translate(1rem, 1rem);
  }
}


.img-four::after {
  content: '';
  background: url(./Catering-child-page-image/Catering-child-component-eight.png);
  bottom: 47%;
  right: 19%;
  position: absolute;
  width: 73px;
  height: 67px;
  background-repeat: no-repeat;
  animation: potato 6.5s ease-out infinite;
}

@keyframes potato {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1rem);
  }
}


.img-five::before {
  content: '';
  background: url(./Catering-child-page-image/Catering-child-component-five.png);
  bottom: 57%;
  right: 12%;
  position: absolute;
  width: 73px;
  height: 67px;
  background-repeat: no-repeat;
  animation: potatoTwo 4.5s ease-in infinite;
}

@keyframes potatoTwo {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1rem);
  }
}

.img-five::after {
  content: '';
  background: url(./Catering-child-page-image/Catering-child-component-four.png);
  bottom: 68%;
  right: 21%;
  position: absolute;
  width: 73px;
  height: 67px;
  background-repeat: no-repeat;
  animation: potatoTwo 4.8s ease-in infinite;
}

@keyframes potatoTwo {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1rem);
  }
}


.img-six::after {
  content: '';
  background: url(./Catering-child-page-image/Catering-child-component-seven.png);
  bottom: 65%;
  right: 0%;
  position: absolute;
  width: 89px;
  height: 160px;
  background-repeat: no-repeat;
  animation: chicken 5.8s ease-in infinite;
}


@keyframes chicken {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1rem);
  }
}

.img-six::before {
  content: '';
  background: url(./Catering-child-page-image/Catering-child-component-ten.png);
  bottom: 64%;
  right: 9%;
  position: absolute;
  width: 89px;
  height: 160px;
  background-repeat: no-repeat;
  animation: stovery 7s ease-in infinite;
}

@keyframes stovery {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1rem);
  }
}


.img-seven::after {
  content: '';
  background: url(./Catering-child-page-image/Catering-child-component-two.png);
  bottom: 74%;
  right: 22%;
  position: absolute;
  width: 89px;
  height: 160px;
  background-repeat: no-repeat;
  animation: onion 7s ease-in infinite;
}

@keyframes onion {
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(1rem);
  }
}


.img-seven::before {
  content: '';
  background: url(./Catering-child-page-image/Catering-child-component-one.png);
  bottom: 82%;
  right: 0%;
  position: absolute;
  width: 89px;
  height: 160px;
  background-repeat: no-repeat;
  animation: treeTwo 7s ease-in infinite;
}

@keyframes treeTwo {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1rem);
  }
}

</style>