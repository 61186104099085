<template>
  <form @submit.prevent="handleSubmit" class="row card-details">
    <div class="col-12">
      <label> Card Number</label>
      <input type="text" v-model="cardNumber" placeholder="1234 5678 9012 3456" />
    </div>
    <div class="col-12">
      <div class="d-flex gap-2">
        <div>
          <label> Expiry date </label>
          <input type="text" v-model="expDate" placeholder="MM/YY" />
        </div>
         <div>
           <label>CVC</label>
           <input type="password" v-model="cvc" placeholder="***" />
         </div>

      </div>

    </div>
    <div class="col-12">
      <label>Card Holder Name</label>
      <input type="text" v-model="name"  placeholder="Enter card holder name"/>
    </div>
    <div col-12>
      <button type="submit">Pay Now</button>
    </div>

  </form>
</template>

<script>
import Stripe from 'stripe';

export default {
  props: ['cart_id', 'selectedPaymentMethod', 'total', 'address'],
  data() {
    return {
      cardNumber: '',
      expDate: '',
      cvc: '',
      name: ''
    };
  },
  methods: {
    async handleSubmit() {
      const stripe = Stripe('pk_test_GuTMsM7jrbY3kz9yaJaodahH00hqTvr4J9');
      const token = await stripe.tokens.create({
        card: {
          number: this.cardNumber,
          exp_month: this.expDate.split('/')[0],
          exp_year: this.expDate.split('/')[1],
          cvc: this.cvc
        }
      });

      // Send token to your server to process payment
      console.log(token)
      this.processPayment(token);

    },
    async processPayment(token) {
      const csrftoken = this.getCookie('csrftoken');
      // Send token to your backend server for payment processing
      try {
        const response = await fetch('../pay-now/?type=' + this.selectedPaymentMethod, {
          method: 'POST',
          headers: {
            'X-CSRFToken': csrftoken,
          },
          body: JSON.stringify({
            token: token.id,
            cart_id: this.cart_id,
            address: this.address,
            amount: this.total,
            payment_method: this.selectedPaymentMethod
          })
        });

        if (response.ok) {
          const data = await response.json();
          if (data.success) {
            // Payment successful, handle success
            window.location.href = `/process/?type=${this.selectedPaymentMethod}&paymentId=${data.charge.id}&payer_id=${data.charge.id}`;
          } else {
            // Payment failed, handle error
          }
        } else {
          // Handle non-200 response status
        }
      } catch (error) {
        console.error('Error:', error);
        // Handle fetch or other errors
      }
    },
    getCookie(name) {
      const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
      return cookieValue ? cookieValue.pop() : '';
    },
  }
};
</script>
<style>
.card-details label{
  color: white;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}
.card-details input{
  border-radius: 10px;
  background: #435971;
}
.card-details input:hover{
  background: #435971;
}
input::placeholder{
  color: #97A2B0 !important;
  opacity: 1;
}

button{
    padding: 15px;
    width: 100%;
    color: white;
    border: 2px solid white;
    border-radius: 15px;
    background: #ffa700b5;
    font-size: 14px;
    font-weight: 600;
    transition: 0.5s;
}
button:hover{
  background: #ffa700;
}


</style>