<template>
  <div class="create-item-child">
    <div class="create-item-child-head">
      <div class="create-item-child-img">
        <img :src="parsedProduct.image" alt="" height="500" width="500">

      </div>
      <div class="create-item-child-details">
        <h1>{{ parsedProduct.title }}</h1>
        <p>{{ parsedProduct.description }}</p>

        <div class="create-item-icon">
          <!--          <span>🔥 46 Calories</span>-->
          <span>⏰ 15 - 20 min</span>
          <!--          <span>⭐ 4.9 (2.6k)</span>-->
          <!--          <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">-->
          <!--            <path fill-rule="evenodd" clip-rule="evenodd"-->
          <!--                  d="M0 8.46874L3.48375 5.00002L0 1.53132L1.05835 0.468317L5.60965 5.00002L1.05835 9.53168L0 8.46874Z"-->
          <!--                  fill="#4A4A4A"/>-->
          <!--          </svg>-->
        </div>
        <!--        <div class="create-item-price-area">-->
        <!--          <div>-->
        <!--            <h1>20<sup>$</sup></h1>-->

        <!--          </div>-->
        <!--          <div class="create-item-quantity">-->
        <!--            <img src="Create-Item-child-page-img/Create-item-child-minus.svg" alt="">-->
        <!--            <span>2</span>-->
        <!--            <img src="Create-Item-child-page-img/Create-item-child-plus.svg" alt="">-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
    <div class="create-item-child-body">
      <div class="create-item-child-body-head">
        <h1>Ingredients</h1>
        <div class="create-items-body-btn">
      <span v-for="(variant, index) in parsedProduct.variant"
            :key="variant.id"
            :class="{ 'active': selectedVariantIndex === index, 'variant-active': selectedVariantIndex === index,'item-selected': isVariantItemSelected && selectedVariantIndex === index }"
            @click="selectVariant(index)">
        {{ variant.title }}
      </span>
        </div>
      </div>

      <div class='create-item-menus row'>
        <div class="create-item-menu col-12 col-lg-5"
             v-for="item in parsedProduct.variant[selectedVariantIndex].items"
             :key="item.id"
             @click="selectItem(item)"
             :class="{ 'selected': item.selected }">
          <img :src="item.image" alt="" width="250" height="150">
          <div class="create-item-priceing-area">
            <h1 class="create-item-name">{{ item.title }}</h1>
            <div class="create-item-price-quantity">
              <p>{{ item.calories }} Cal</p>
              <h1>{{ item.price }}<sup>$</sup></h1>
              <div v-if="item.is_incrementable" :class="['item-child-quantity', { 'visible': item.showQuantity }]">
                <div role="button" class="decrease-quantity" @click.stop="decreaseQuantity(item)">
                  <img src="./Create-Item-child-page-img/Create-item-child-minus.svg" alt="">
                </div>
                <span>{{ item.quantity }}</span>
                <div role="button" class="increase-quantity" @click.stop="increaseQuantity(item)">
                  <img src="./Create-Item-child-page-img/Create-item-child-plus.svg" alt="">
                </div>
              </div>
              <div class="item-options" v-if="item.options && item.options.length > 0">
                <div v-for="option in availableOptions(item)" :key="option.id">
                   <input type="radio" :id="'option-' + option.id" :value="option.id" v-model="item.selectedOption"
                     @click.stop @change="selectOption(item, option)">
                  <label :for="'option-' + option.id">{{ option.title }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex ms-auto" style="width: fit-content;">
        <button class="btn btn-outline-primary mt-5 me-5 text-nowrap" @click="addToCart(1)"> Add to cart</button>
        <button class="btn btn-outline-primary mt-5 me-lg-5 text-nowrap" @click="addToCart(2)"> Buy Now</button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: ['product'],
  data() {
    return {
      parsedProduct: JSON.parse(this.product),
      selectedVariantIndex: 0, // To keep track of the selected variant
      cart: []
    };
  },
  created() {
    console.log(this.parsedProduct);
    this.initializeShowQuantity();
  },
  computed: {
    isVariantItemSelected() {
      return this.parsedProduct.variant[this.selectedVariantIndex].items.some(item => item.selected);
    }
  },
  methods: {
    initializeShowQuantity() {
      this.parsedProduct.variant.forEach(variant => {
        variant.items.forEach(item => {
          item.showQuantity = false;
          item.quantity = 0; // Initialize quantity for incrementable items
          item.selected = false;
          item.selectedOption = null;
        });
      });
    },
    increaseQuantity(item) {
      if (item.quantity < item.maximum_increment) {
        item.quantity++;
      }
    },
    decreaseQuantity(item) {
      if (item.quantity > 0) {
        item.quantity--;
      }
    },
    selectVariant(index) {
      this.selectedVariantIndex = index;
    },
    selectItem(item) {
      if (item.is_incrementable) {
        item.showQuantity = !item.showQuantity;
      }
      item.selected = !item.selected; // Toggle selected property
    },
    selectOption(item, option) {
      item.selectedOption = option.id;
    },
    availableOptions(item) {
      return item.options.filter(option => option.availability);
    },
    getSelectedItems() {
      const selectedItems = {
        productId: this.parsedProduct.id,
        productTitle: this.parsedProduct.title,
        productImage: this.parsedProduct.image,
        variants: {}
      };

      this.parsedProduct.variant.forEach(variant => {
        selectedItems.variants[variant.title] = variant.items.filter(item => item.selected).map(item => ({
          id: item.id,
          title: item.title,
          image: item.image,
          price: item.price,
          calories: item.calories,
          nutrition_type: item.nutrition_type,
          quantity: item.quantity > 0 ? item.quantity : 1,
          selectedOptions: item.selectedOption
        }));
      });

      return selectedItems;
    },
    getTotalPriceAndCalories(productVariants) {
      let totalPrice = 0;
      let totalCalories = 0;

      productVariants.forEach(variant => {
        variant.forEach(item => {
          totalPrice += parseFloat(item.price) * item.quantity;
          totalCalories += parseFloat(item.calories) * item.quantity;
        });
      });

      return {totalPrice, totalCalories};
    },
    getCookie(name) {
      let cookieValue = null;
      if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          if (cookie.substring(0, name.length + 1) === (name + '=')) {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
            break;
          }
        }
      }
      return cookieValue;
    },
    addToCart(option) {
      const selectedItems = this.getSelectedItems();
      const { totalPrice, totalCalories } = this.getTotalPriceAndCalories(Object.values(selectedItems.variants));
      const csrfToken = this.getCookie('csrftoken');
      fetch('../create-cart/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken
        },
        body: JSON.stringify({product_title: selectedItems.productTitle, product: selectedItems.productId, total_price: totalPrice, total_cal: totalCalories, variants: selectedItems.variants})
      })
          .then(response => {
            response.json()
            this.emitter.emit('add-to-cart', response.data);
          })
          .then(data => {
            console.log('Success:', data);
            if( option === 1) {
              window.location.href = window.location.origin + '/#menu';
            } else {
              window.location.href = window.location.origin + '/checkout';
            }

          })
          .catch((error) => {
            console.error('Error:', error);
          });
    }
  }
};


</script>
<style>
.selected {
  border: 2px solid #ff6600 !important; /* Example border color */
}

.items-seleted {
  background: limegreen !important;
  color: white !important;
}

.variant-active {
  background: #F99F00 !important;
  color: white !important;
}

.create-item-child {
  padding: 80px;
  display: flex;
  flex-direction: column;
  gap: 80px;

}

.create-item-child-head {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.create-item-child-img {
  width: 500px;
  height: 500px;
  filter: drop-shadow(8px 8px 10px gray);
}

.create-item-child-details {
  display: flex;
  flex-direction: column;

}

.create-item-child-details h1 {
  color: #691209;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 100% */
  margin-bottom: 36px;

}

.create-item-child-details p {
  color: #4A4A4A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 200% */
  padding-bottom: 18px;
  margin-bottom: 28px;
  border-bottom: 2px solid #691209;
}

.create-item-icon {
  display: flex;
  gap: 22px;
  align-items: center;
  margin-bottom: 40px;
}

.create-item-icon span {
  color: #4A4A4A;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 100% */

}

.create-item-price-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.create-item-price-area h1 {
  color: #691209;
  font-size: 60px;
  font-style: normal;
  font-weight: 900;
  line-height: 60px; /* 100% */
  margin-bottom: 0;
}

.create-item-price-area h1 sup {
  color: #691209;
  font-size: 31px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;

}

.create-item-quantity {
  display: flex;
  align-items: center;
  gap: 28px;
}

.create-item-quantity img {
  width: 40px;
  height: 40px;
}

.create-item-quantity span {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 62.5% */
}

.create-item-child-body-head h1 {
  color: #691209;
  margin-bottom: 40px;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 100% */
}

.create-items-body-btn {
  display: flex;
  align-items: center;
  gap: 60px;
  margin-bottom: 80px;
}

.create-items-body-btn span {
  padding: 14px 28px;
  border: 2px solid #E7EAF3;
  background: #FFF;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 100% */
  cursor: pointer;
  transition: 0.5s;
}

.create-items-body-btn span:hover {
  background: #F99F00;
  color: white;
}

.create-item-menus {

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
}

.create-item-menu {
  padding: 20px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  border: 1.5px solid #E7EAF3;
  background: #FFF;
  justify-content: space-between;
  transition: .3s;
}

.create-item-menu:hover {
  border-radius: 20px;
  border: 2px solid #691209;
  background: #FFF;
  box-shadow: 0px 27px 80px 0px rgba(201, 203, 208, 0.27);
}

.create-item-menu img {
  width: 250px;
  height: 166.667px;
}

.create-item-menu .create-item-name {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
  text-transform: capitalize;
  margin-bottom: 24px;
}

.create-item-price-quantity {
  display: flex;
  align-items: center;
  gap: 32px;
}

.create-item-price-quantity p {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 100% */
  text-transform: capitalize;
  margin-bottom: 0;
}

.create-item-price-quantity h1 {
  color: #1E1E1E;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 222.222% */
  margin-bottom: 0;
}

.create-item-price-quantity sup {
  color: #1E1E1E;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
}

.item-child-quantity {
  display: flex;
  align-items: center;
  gap: 20px;
}

.item-child-quantity img {
  width: 20px;
  height: 20px;
}

.item-child-quantity span {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 100% */
}

@media only screen and (max-width: 599px) {
  .create-item-child-img {
    width: 300px;
    height: 301px;
  }

  .create-item-child-img img {
    width: 100%;
    height: 100%;
  }

  .create-item-child {
    padding: 80px 20px;
  }

  .create-item-child-head {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    flex-direction: column;
  }

  .create-item-menus {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .create-items-body-btn {
    gap: 10px;
    overflow: hidden;
  }

  .create-items-body-btn span {
    padding: 15px 16px;
    font-size: 1.5rem;
    line-height: 14px;
    text-align: center;
  }

  .create-item-menu > img {
    width: 90px;
    height: 90px;
  }

  .create-item-menus {

    gap: 40px;
  }

  .create-item-quantity img {
    width: 20px;
    height: 20px;
  }

  .create-item-quantity {
    gap: 15px;
  }

  .item-child-quantity {
    gap: 15px;
  }

  .create-item-price-area h1 {
    color: #691209;
    font-size: 45px;
  }

  .create-item-price-quantity {
    gap: 10px;
  }

  .create-item-price-quantity h1 {
    font-size: 15px;
  }

  .create-item-quantity span {
    color: #000;
    font-size: 25px;
  }

  .create-item-menu .create-item-name {
    font-size: 18px;
  }

  .create-item-price-quantity p {
    font-size: 14px;
  }

  .item-child-quantity img {
    width: 15px;
    height: 15px;
  }

  .item-child-quantity span {
    font-size: 16px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 991px) {
  .create-item-child-img {
    width: 300px;
    height: 301px;
  }

  .create-item-child-img img {
    width: 100%;
    height: 100%;
  }

  .create-item-child {
    padding: 80px 40px;
  }

  .create-item-child-head {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    flex-direction: column;
  }

  /* .create-item-menus {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
  } */
  .create-items-body-btn {
    gap: 10px;
  }

  .create-items-body-btn span {
    padding: 15px 16px;
    font-size: 1.5rem;
    line-height: 14px;
    text-align: center;
  }

  .create-item-menu > img {
    width: 220px;
    height: 150.667px;
  }

  .create-item-menus {

    gap: 40px;
  }

  .create-item-quantity img {
    width: 20px;
    height: 20px;
  }

  .create-item-quantity {
    gap: 20px;
  }

  .create-item-price-area h1 {
    color: #691209;
    font-size: 45px;
  }

  .create-item-quantity span {
    color: #000;
    font-size: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1299px) {
  .create-item-icon {
    gap: 17px;
  }

  .create-item-child {
    padding: 80px 40px;
  }

  .create-items-body-btn {
    gap: 16px;
  }

  .create-items-body-btn span {
    padding: 14px 18px;
    font-size: 1.5rem;
  }

  .create-item-menus {
    gap: 30px;
  }

  .create-item-menu > img {
    width: 170px;
    height: 148.667px;
  }

  .create-item-menu {
    padding: 15px;
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1499px) {
  .create-items-body-btn {
    gap: 30px;
  }
}

</style>