import {createApp} from 'vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import { BootstrapVueNext } from 'bootstrap-vue-next';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';
import mitt from 'mitt';
const emitter = mitt();

import HelloWorld from './components/HelloWorld.vue'
import NavBar from './components/NavBar.vue'
import FooterComp from './components/FooterComp.vue'
import HeroArea from './components/HeroArea.vue'
import CreateOwn from './components/CreateOwn.vue'
import FoodDelivery from './components/FoodDelivery.vue'
import AppSection from './components/AppSection.vue'
import NewsLetter from './components/NewsLetter.vue'
import FeaturedSection from './components/FeaturedSection.vue'
import ContactPage from './components/ContactPage.vue'
import SignupPage from './components/SignupPage.vue'
import LoginPage from './components/LoginPage.vue'
import VerifyPage from './components/VerifyPage.vue'
import RewardPage from './components/RewardPage.vue'
import CreateOwnPage from './components/CreateOwnPage.vue'
import CateringPage from './components/CateringPage.vue'
import CheckoutPage from './components/CheckoutPage.vue'
import OurBelife from './components/OurBelife.vue'
import OrderStatus from './components/OrderStatus.vue'
import PaymentForm from './components/PaymentForm.vue'
import CateringSection from './components/CateringSection.vue'

const app = createApp({})

app.component('hello-world', HelloWorld)
app.component('nav-bar', NavBar)
app.component('footer-comp', FooterComp)
app.component('hero-area', HeroArea)
app.component('create-item', CreateOwn)
app.component('food-delivery', FoodDelivery)
app.component('app-section', AppSection)
app.component('news-letter', NewsLetter)
app.component('featured-section', FeaturedSection)
app.component('contact-page', ContactPage)
app.component('sign-up', SignupPage)
app.component('log-in', LoginPage)
app.component('verify-page', VerifyPage)
app.component('reward-page', RewardPage)
app.component('create-own-page', CreateOwnPage)
app.component('catering-page', CateringPage)
app.component('check-out', CheckoutPage)
app.component('our-belief', OurBelife)
app.component('order-status', OrderStatus)
app.component('payment-form', PaymentForm)
app.component('catering-section', CateringSection)

app.use(BootstrapVueNext);
app.config.globalProperties.emitter = emitter;

app.mount('body')