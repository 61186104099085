<template>
  <div class="login-bg">
    <div class="login-bg-img">
      <img src="./Login-image/Log-in-bg.png" alt="">

      <div class="login">
        <div class="login-logo">
          <img src="./Login-image/Login-logo.png" alt="">
        </div>
        <h1 class="login-title">Sign In With Our <br><span>Best</span> Dishes</h1>

        <form @submit.prevent="handleSubmit">
          <div class="login-address">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">
              <path
                  d="M12.5007 10.9167C14.8018 10.9167 16.6673 9.0512 16.6673 6.75001C16.6673 4.44882 14.8018 2.58334 12.5007 2.58334C10.1995 2.58334 8.33398 4.44882 8.33398 6.75001C8.33398 9.0512 10.1995 10.9167 12.5007 10.9167Z"
                  fill="#691209"/>
              <path
                  d="M20.8337 18.7292C20.8337 21.3177 20.8337 23.4167 12.5003 23.4167C4.16699 23.4167 4.16699 21.3177 4.16699 18.7292C4.16699 16.1406 7.89824 14.0417 12.5003 14.0417C17.1024 14.0417 20.8337 16.1406 20.8337 18.7292Z"
                  fill="#691209"/>
            </svg>
            <input type="text" name="email" v-model="email" placeholder="Phone">
          </div>
          <div class="login-pass">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">
              <path
                  d="M12.4838 2.58333C15.6804 2.58333 18.2525 5.09873 18.2525 8.20417V9.8014C20.0466 10.3614 21.3538 11.9856 21.3538 13.9254V19.068C21.3538 21.4696 19.3628 23.4167 16.9082 23.4167H8.09217C5.6365 23.4167 3.64551 21.4696 3.64551 19.068V13.9254C3.64551 11.9856 4.95379 10.3614 6.74685 9.8014V8.20417C6.75743 5.09873 9.32953 2.58333 12.4838 2.58333ZM12.4944 14.442C11.9863 14.442 11.5735 14.8457 11.5735 15.3425V17.6406C11.5735 18.1478 11.9863 18.5515 12.4944 18.5515C13.013 18.5515 13.4258 18.1478 13.4258 17.6406V15.3425C13.4258 14.8457 13.013 14.442 12.4944 14.442ZM12.505 4.39483C10.3563 4.39483 8.60977 6.09247 8.59918 8.18346V9.57677H16.4002V8.20417C16.4002 6.10282 14.6537 4.39483 12.505 4.39483Z"
                  fill="#691209"/>
            </svg>
            <input type="password" v-model="password" placeholder="Password">
          </div>
          <div class="remember-forget d-none">
            <div class="remember">
              <input type="checkbox" name="remember" v-model="remember">
              <h4>Remember me</h4>
            </div>

            <h2>Forget Password</h2>
          </div>

          <input class="login-btn" type="submit" value="Login">
          <div class="form-error" v-if="showError">{{ errorMessage }}</div>
          <div class="any-account">
            <h1>Don't have an account?<span><a href="../signup"> Sign up!</a></span></h1>
          </div>
        </form>
        <p class="keebabi-footer">Thank you for choosing <span>Keebabi!</span></p>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";

export default {
  data() {
    return {
      email: '',
      password: '',
      remember: false
    };
  },
  methods: {
    async handleSubmit() {
      // Collect the form data
      const formData = new FormData();
      formData.append('username', this.email);
      formData.append('password', this.password);
      formData.append('remember', this.remember);

      try {
        const response = await fetch(window.location.href, {
          method: 'POST',
          headers: {
            'X-CSRFToken': this.getCookie('csrftoken')
          },
          body: formData
        });

        if (response.ok) {
          const data = await response.json();
          if (data.success) {
            // Redirect to another page on successful login
            window.location.href = '../'; // Change this to your desired URL
          } else {
            // Handle login failure (e.g., show error message)
            this.showError = true;
            this.errorMessage = 'Phone number or password is incorrect.';
          }
        } else {
          this.showError = true;
          this.errorMessage = 'Phone number or password is incorrect.';
        }
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        alert('There was a problem with the fetch operation: ' + error.message);
      }
    },
    getCookie(name) {
      const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
      return cookieValue ? cookieValue.pop() : '';
    },
  },
  setup() {
    const showError = ref(false);
    const errorMessage = ref('');

    return {
      showError,
      errorMessage
    };
  }
};
</script>


<style>
.form-error {
  display: block;
  color: red;
  margin-top: 10px;
}

.login-bg-img {
  width: 100%;
  position: relative;

}

.login-bg-img img {
  width: 100%;
  height: 932px;
}

.login {
    width: 40%;
    display: flex;
    flex-direction: column;
    text-align: center;
    position: absolute;
    transform: translate(74%, -116%);
}

form {
  width: 420px;
  margin: 0 auto;
}

.login-logo img {
  width: 100px;
  height: 100px;
  margin-bottom: 18px;
}

.login-title {
  color: #1E1E1E;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 133.333% */
  letter-spacing: 1.8px;
  text-transform: capitalize;
  margin-bottom: 48px;
}

.login-title span {
  color: #F99F00;
}

.login-address {
  display: flex;
  align-items: center;
  gap: 36px;
  padding: 10px 36px;
  border: 1px solid rgba(19, 33, 68, 0.10);
  background: #FFF;
  box-shadow: 0px 0px 12px 0px rgba(204, 204, 204, 0.20);
  width: 100%;
  margin-bottom: 36px;
}

.login-pass {
  display: flex;
  align-items: center;
  gap: 36px;
  padding: 10px 36px;
  border: 1px solid rgba(19, 33, 68, 0.10);
  background: #FFF;
  box-shadow: 0px 0px 12px 0px rgba(204, 204, 204, 0.20);
  width: 100%;
  margin-bottom: 36px;
}

.login-address input {
  border: none;
  outline: none;
  color: #4A4A4A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
  letter-spacing: 0.42px;
  background: transparent;
  padding: 10px 0;
  width: 100%;
}

.login-pass input {
  border: none;
  outline: none;
  color: #4A4A4A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
  letter-spacing: 0.42px;
  background: transparent;
  padding: 10px 0;
  width: 100%;
}

.remember-forget {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.remember {
  display: flex;
  align-items: center;
  gap: 12px;
}

.remember h4 {
  color: #4A4A4A;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
  letter-spacing: 0.3px;
  margin-bottom: 0;
}

.remember-forget h2 {
  color: #4A4A4A;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 100% */
  margin-bottom: 0;
}

.login-btn {
  width: 100%;
  border: 1px solid rgba(19, 33, 68, 0.10);
  background: #691209;
  box-shadow: 0px 0px 12px 0px rgba(204, 204, 204, 0.20);
  padding: 14px;
  margin-bottom: 40px;
  color: #FFF;
  text-decoration: none;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 100% */
  letter-spacing: 0.54px;

}

.any-account {
  margin-bottom: 60px;
}

.any-account h1 {
  color: #494949;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
  margin-bottom: 0;
}

.any-account h1 span {
  color: #FF6B00;
}

.keebabi-footer {
  color: #6E6D7A;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 12px; /* 85.714% */
  letter-spacing: 2.8px;
  margin-bottom: 0;
}

.keebabi-footer span {
  color: #691209;
}

    @media only screen and  (max-width: 599px) {
      .login {
        width: 70%;
        display: flex;
        flex-direction: column;
        text-align: center;
        position: absolute;
        transform: translate(23%, -116%);
      }
      .login-address {
        gap: 20px;
      }
    }
@media only screen and (min-width: 600px) and (max-width: 991px) {
  .login {
    width: 55%;
    display: flex;
    flex-direction: column;
    text-align: center;
    position: absolute;
    transform: translate(42%, -116%);
  }
}

</style>
