<template>
  <div id="menu" class="create-item">
    <div class="create-item-header">
      <h1>Create Your Own</h1>
      <p>
        Experience the Fresh Taste At A Great Price, Exclusively Available For
        Those Who Love Delicious Meals And Are Always Hungry For More!
      </p>
    </div>
    <div class="create-items-body">
      <div role="button" class="create-items-child" v-for="item in parsedProducts" :key="item.id" @click="handleClick(item.id)">
        <img class="create-items-child-img" :src="item.image" alt="" />
        <div class="two-side-animation"></div>
        <p>{{ item.title }}</p>
        <div class="create-item-details-icons"></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ['products'],
  data() {
    return {
      parsedProducts: JSON.parse(this.products),
    };
  },
  methods: {
    handleClick(key) {
      // Redirect to the provided URL
      const url = `${window.location.origin}/create-your-own/${key}`;
      window.location.href = url;
    }
  }
};
</script>

<style>
.create-item {
  margin-bottom: 70px;
  padding: 0 80px;
}

.create-item-header {
  width: 50%;
  text-align: center;
  margin: auto;
  margin-bottom: 80px;
}

.create-item-header h1 {
  color: #691209;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  /* 100% */
  letter-spacing: 1.44px;
  margin-bottom: 12px;
}

.create-item-header p {
  color: #333;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 200% */
  margin: 0;
}

.create-items-body {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-items: center;
  text-align: center;
  gap: 80px;
}

.create-items-child {
  position: relative;
  display: flex;
  align-items: end;
  justify-content: center;
  border-radius: 20px;
  border: 1px solid #e6e6e6;
  background: #fff;
  padding: 40px;
  width: 380px;
  height: 432px;
  position: relative;
  overflow: hidden;
  transition: 0.5s ease;
}

.create-items-child::before {
  content: "";
  position: absolute;
  top: -313px;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("./Create-item-image/create-item-hover-background\ .png");
  background-repeat: no-repeat;
  transition: 0.5s ease;
}

.create-items-child:hover::before {
  top: 0px;
}

.create-items-child p {
  margin-top: 20px;
  margin-bottom: 0;
  color: #691209;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  /* 100% */
  z-index: 1;
  position: absolute;
  transition: 0.8s;
  bottom: 40px;
}

.create-items-child .create-items-child-img {
  width: 250px;
  height: 250px;
  margin: 25px;
  position: absolute;
  top: 50px;
  left: 10%;
  transition: 0.8s;

}
.create-items-child:hover .create-items-child-img{
        animation: spin 10s linear infinite;
        -webkit-animation: spin 10s linear infinite;
        -moz-animation: spin 10s linear infinite;
}

.two-side-animation::after {
  content: "";
  position: absolute;
  top: 155px;
  left: 100px;
  width: 120px;
  height: 100px;
  transform: rotate(-32deg);
  background: url("./Create-item-image/Two-side-animation-two.png");
  background-repeat: no-repeat;
  transition: 0.5s ease;
  z-index: -1;
}

.create-items-child:hover .two-side-animation::after {
  transform: rotate(-8deg) translate(-51px, -80px);
  z-index: 1;
}

.two-side-animation::before {
  content: "";
  position: absolute;
  top: 140px;
  right: 110px;
  width: 101px;
  height: 100px;
  transform: rotate(32deg);
  background: url("./Create-item-image/Two-side-animation-one.png");
  background-repeat: no-repeat;
  transition: 0.5s ease;
  z-index: -1;
}

.create-items-child:hover .two-side-animation::before {
  z-index: 1;
  transform: rotate(9deg) translate(74px, -67px);
}

@keyframes spiny {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spiny {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes spiny {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.create-items-child:hover p {
  color: white;
  bottom: 185px;
}

.create-items-child:hover img {
  top: -140px;
}

.create-item-details-icons {
  position: absolute;
  left: 0%;
  bottom: -21%;
  transition: 0.8s;
  opacity: 0;
}

.create-items-child:hover .create-item-details-icons {
  bottom: 0%;
  opacity: 1;
}

.create-item-details-icons::after {
  content: "";
  position: absolute;
  bottom: 47px;
  left: 124px;
  width: 63.231px;
  height: 26.577px;
  background: url("./Create-item-image/Create-item-bottom-arrow-icon.png");
  background-repeat: no-repeat;
  animation: arrow 3s infinite;
}

@keyframes arrow {

  0%,
  100% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(15px);
  }
}

.create-item-details-icons::before {
  content: "";
  position: absolute;
  bottom: 38px;
  left: 163px;
  width: 55px;
  height: 50px;
  background: url("./Create-item-image/Create-item-bottom-circle-icon.png");
  background-repeat: no-repeat;
}

@media only screen and (max-width: 599px) {
     .create-item-header {
        width: 90%;
        margin-bottom: 40px;
      }
    .create-item {
        margin-bottom: 40px;
        padding: 0 20px;
    }
      .create-items-body {
    gap: 20px;
    grid-template-columns: repeat(1, 1fr);
  }
  .create-items-child {
    padding: 20px;
    width: 330px;
    height: 432px;
}
.create-item-header h1 {
    color: #691209;
    font-size: 28px;
}
.create-items-child .create-items-child-img {
    left: 3%;
  }

}


@media only screen and (min-width: 600px) and (max-width: 991px) {
     .create-item-header {
        width: 90%;
        margin-bottom: 40px;
      }
     .create-item {
        margin-bottom: 40px;
     }
      .create-items-body {
    gap: 20px;
    grid-template-columns: repeat(1, 1fr);
    }
  .create-items-child {
    padding: 20px;
    width: 330px;
    height: 432px;
  }
 .create-items-child .create-items-child-img {
    left: 3%;
  }

}


@media only screen and (min-width: 992px) and (max-width: 1299px) {
  .create-item {
    padding: 0 40px;
  }

  .create-items-child .create-items-child-img {
    margin: 10px;
  }

  .create-items-body {
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  }

  .create-items-child {
    padding: 10px;
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1499px) {
  .create-items-body {
    gap: 40px;
  }

  .create-items-child {
    padding: 25px;
  }
}
</style>
