<template>
  <div class="join-newsletter">
    <div class="join-newsletter-header ">
      <h1>Join Our Newsletter</h1>
      <p>To stay up to date on all the latest news and offers from us.</p>
      <div class="input-group mb-3 email-box">
        <input
            type="text"
            class="form-control"
            placeholder="Enter your email address"
            v-model="email"
        />
        <p class="input-group-text m-0" role="button" @click="handleSubmit">JOIN</p>
      </div>
      <p v-if="successMessage" class="success-message">{{ successMessage }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      successMessage: ''
    };
  },
  methods: {
    async handleSubmit() {
      if (!this.email) {
        alert('Please enter your email address.');
        return;
      }
      const formData = new FormData();
      formData.append('email', this.email);

      try {
        const csrftoken = this.getCookie('csrftoken');
        const response = await fetch('../newsletter/', {
          method: 'POST',
          headers: {
            'X-CSRFToken': csrftoken, // Include CSRF token in the header
          },
          body: formData
        });

        if (response.ok) {
          this.successMessage = 'Thank you for joining our newsletter!';
          this.email = ''; // Clear the input field
        } else {
          const data = await response.json();
          this.successMessage = data.message;

        }
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        this.successMessage = 'Looks like something went wrong!.';
      }
    },
    getCookie(name) {
      const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
      return cookieValue ? cookieValue.pop() : '';
    },
  }
};
</script>


<style>
.success-message {
  color: green;
  margin-top: 10px;
}

.join-newsletter {
  display: flex;
  width: 100%;
  height: 100vh;

  justify-content: center;
  background: url('./Join-our-newsletter/join-our-nesletter.png');
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.join-newsletter-header {
  margin-top: 168px;
}

.join-newsletter-header h1 {
  color: #691209;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  /* 100% */
  letter-spacing: 2.4px;
  margin-bottom: 24px;
}

.join-newsletter-header p {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 100% */
  margin-bottom: 64px;
}

.email-box {
  border-radius: 50px;
  border: 1px solid #691209;

}

.email-box input {
  border: none;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 50px 0 0 50px;
  color: #2D2D2D;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  /* 100% */
  letter-spacing: 0.7px;
  padding-left: 20px;
}

.email-box p {
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  /* 100% */
  letter-spacing: 0.7px;
  padding: 20px;
  border-radius: 0px 50px 50px 0px;
  background: #691209;

}
@media only screen and  (max-width: 599px) {
 .join-newsletter-header{
    padding: 20px
 }
.join-newsletter-header h1 {
    color: #691209;
    font-size: 28px;
    }
}
</style>
