<template>
  <div class="reward-page">
    <img src="./Rewards-image/Reward-bg.png" alt="Background">
    <div class="reward-page-head d-flex flex-column">
      <h1>Get points On Every </h1>
      <h2>Order!</h2>
    </div>
    <div class="all-compo">
      <div class="compo-one">
        <img src="./Rewards-image/Reward-bg-compo-2.png" alt="">
      </div>
      <div class="compo-two">

        <img src="./Rewards-image/Reward-bg-compo.png" alt="">
      </div>
    </div>
  </div>
  <div class="reward-background">
    <div class="reward-white-bg-img">
      <img src="./Rewards-image/Reward-bg-white.png" alt="">
      <div class="reward-white-bg-text">
        <h1>My Rewards : 0 Points</h1>
      </div>
    </div>
    <div class="reward-unlock-more">
      <div class="reward-unlock-more-header">
        <h1>Unlock More Rewards</h1>
        <h1>Love More Keebabi</h1>
      </div>
      <div class="reward-unlock-more-body">
        <div class="reward-unlock-more-body-part d-flex">
          <div class="reward-unlock-more-body-left">
            <img src="./Rewards-image/Reward-more-one.png" alt="">
          </div>
          <div class="reward-unlock-more-body-right">
            <p class="mb-0">
              Enjoy $5 off any entree on your first order! Treat yourself to our delicious food and save on your first
              meal with us. Don’t miss out on this great deal!
            </p>
          </div>
        </div>

        <div class="reward-unlock-more-body-part d-flex flex-row-reverse">
          <div class="reward-unlock-more-body-left">
            <img src="./Rewards-image/Reward-more-two.png" alt="">
          </div>
          <div class="reward-unlock-more-body-right">
            <p class="mb-0">
              Get 10 points for every $1 spent! Dine in, order online, or use our site to start earning points and
              unlock great rewards!
            </p>
          </div>
        </div>


        <div class="reward-unlock-more-body-part d-flex">
          <div class="reward-unlock-more-body-left">
            <img src="./Rewards-image/Reward-more-three.png" alt="">
          </div>
          <div class="reward-unlock-more-body-right">
            <p class="mb-0">
              Refer a friend to us, and when they make their first purchase, both of you get $3 off your next order.
              It's our way of saying thank you for spreading the word about our delicious food!
            </p>
          </div>
        </div>


        <div class="reward-unlock-more-body-part d-flex flex-row-reverse">
          <div class="reward-unlock-more-body-left">
            <img src="./Rewards-image/Reward-more-four.png" alt="">
          </div>
          <div class="reward-unlock-more-body-right">
            <p class="mb-0">
              Make your birthday sweeter with us! Celebrate your special day and enjoy a complimentary dessert on the
              house. It's our way of making your day even more memorable!
            </p>
          </div>
        </div>


        <div class="reward-unlock-more-body-part d-flex">
          <div class="reward-unlock-more-body-left">
            <img src="./Rewards-image/Reward-more-five.png" alt="">
          </div>
          <div class="reward-unlock-more-body-right">
            <p class="mb-0">
              Join us for happy hour! Savor discounted appetizers and drinks, and unwind with friends. It's the perfect
              way to elevate your evening without breaking the bank!
            </p>
          </div>
        </div>


      </div>
    </div>
  </div>
  <div class="reward-best-deal">
    <div class="reward-best-deal-left ">
      <img src="./Rewards-image/Reward-Deal-img.png" alt="">
    </div>
    <div class="reward-best-deal-right w-50">
      <p>BEST DEAL</p>
      <h1>Decadent Brownie Bliss</h1>
      <h4>Get a free drink, dessert, chips & guacamole, or a side with your next meal! Order now and collect
        reward.</h4>
      <div class="reward-best-deal-item-ingredient">
        <div class="reward-best-deal-item-top">
          <div class="reward-best-deal-item-left">
            <h1>Iteam</h1>
            <h3>Beef, Onion, Mint leaf...</h3>
          </div>
          <div class="reward-best-deal-item-right">
            <h1>INGREDIENT:</h1>
            <h3>Energy, Protein, Sugars</h3>
          </div>
        </div>
        <div class="reward-best-deal-item-bottom">
          <div class="reward-best-deal-item-left">
            <h1>EXPIRY DATE:</h1>
            <h3>23 - 05 - 2024 02:00 AM</h3>
          </div>
          <div class="reward-best-deal-item-right">
            <h1>Quantity:</h1>
            <h3>2kg – 5kg</h3>
          </div>
        </div>

        <div class="reward-best-deal-btn">
          <a href="/#menu">ORDER NOW</a>
        </div>
      </div>


    </div>
  </div>
</template>

<script>

export default {
  props: [],
};
</script>

<style>
.reward-page {
  position: relative;
  height: 800px;

}

.reward-page > img {
  width: 100%;
  height: 100%;


}

.all-compo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: end;
  position: absolute;
  top: 0;
}

.all-compo .compo-one > img {
  width: 380px;
  height: 200px;
  transform: translate(45%, 344%);
}

.all-compo .compo-two > img {
  width: 350px;
  height: 500px;
  transform: translate(0%, 89%);
}

.reward-page .reward-page-head {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: -70px;
}

.reward-background {
  background: url(./Rewards-image/Reward-main-bg.png);

}

.reward-white-bg-img img {
  width: 100%;
  margin: 220px 0;
}

.reward-white-bg-img {
  position: relative;
}

.reward-white-bg-text h1 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 0;
}

.reward-page-head h1 {
  color: #F99F00;
  text-align: center;
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.30);
  font-size: 76px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 91.2px */
  letter-spacing: 3.8px;
}

.reward-page-head h2 {
  color: #F99F00;
  font-size: 76px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 3.8px;
}

.reward-white-bg-text h1 {
  color: #691209;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.reward-unlock-more {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 150px 80px;
}

.reward-unlock-more-header {
  margin-bottom: 80px;
}

.reward-unlock-more-header h1 {
  color: #691209;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 57.6px */

}

.reward-unlock-more-body-part {
  gap: 100px;
  margin-bottom: 100px;
}

.reward-unlock-more-body-left img {
  margin-bottom: 40px;
}

.reward-unlock-more-body-right p {
  margin-top: 120px;
  color: #1E1E1E;
  text-align: justify;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 180% */
}

.reward-best-deal {
  padding: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.reward-best-deal-right p {
  color: #4A4A4A;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px; /* 164.286% */
  text-transform: uppercase;
  margin-bottom: 20px;
}

.reward-best-deal-right > h1 {
  color: #691209;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 57.6px */
  margin-bottom: 20px;
}

.reward-best-deal-right h4 {
  color: #1E1E1E;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 200% */
  margin-bottom: 20px;
}

.reward-best-deal-item-ingredient {
  display: flex;
  flex-direction: column;

}

.reward-best-deal-item-top {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.reward-best-deal-item-bottom {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
}

.reward-best-deal-item-left h1 {
  color: #1E1E1E;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  padding-bottom: 10px;
  border-bottom: 2px dashed #CCC;
  width: 250px;
  margin: 0;
}

.reward-best-deal-item-right h1 {
  color: #1E1E1E;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  padding-bottom: 10px;
  border-bottom: 2px dashed #CCC;
  width: 250px;
  margin: 0;
}

.reward-best-deal-item-left h3 {
  color: #1E1E1E;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 200% */
  padding-top: 10px;
}

.reward-best-deal-item-right h3 {
  color: #1E1E1E;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 200% */
  padding-top: 10px;
}

.reward-best-deal-btn {
  border-radius: 6px;
  border: 2px solid #691209;
  text-align: center;
  padding: 12px 0;
}

.reward-best-deal-btn a {
  text-decoration: none;
  color: #691209;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px; /* 176.923% */
  text-transform: uppercase;
  padding: 12px 0px;
}
@media only screen and (max-width: 599px){
    .all-compo .compo-two > img {
        width: 200px;
        height: 350px;
        transform: translate(0%, 156%);
    }
    .all-compo .compo-one > img {
        width: 270px;
    }
    .reward-white-bg-text h1 {
    font-size: 32px;
    }
    .reward-unlock-more {
    padding: 0 50px 80px;
    }
  .reward-unlock-more-header h1 {
    font-size: 29px;
  }
  .reward-unlock-more-body-part{
    display: block !important;
  }
    .reward-best-deal {
        padding: 20px !important;
        display: block !important;
        text-align: center;
    }
  .reward-best-deal-right{
    width: 100% !important;
  }
  .reward-best-deal-right > h1 {
    font-size: 40px;
  }
  .reward-page-head h1 {
    font-size: 50px;
  }
  .reward-page-head h2 {
    font-size: 50px;
  }
  .reward-best-deal-left img{
    width: 300px;
  }
  .reward-best-deal-item-top{
  flex-direction: column;
  align-items: center;
}
  .reward-best-deal-item-bottom{
      flex-direction: column;
      align-items: center;
  }
  .reward-best-deal-item-left{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .reward-best-deal-item-right{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

}





@media only screen and (min-width: 600px) and (max-width: 991px){
  .all-compo .compo-two > img {
    width: 266px;
    height: 467px;
    transform: translate(0%, 100%);
  }
  .all-compo .compo-one > img {
    width: 320px;
  }
  .reward-unlock-more-header h1 {
    font-size: 29px;
  }
  .reward-unlock-more-body-part{
    display: block !important;
  }
  .reward-best-deal {
    padding: 40px;
    display: block !important;
    text-align: center;

  }
  .reward-best-deal-right{
    width: 100% !important;
  }
  .reward-best-deal-right > h1 {
    font-size: 40px;
  }

}
@media only screen and (min-width: 992px) and (max-width: 1299px) {
  .reward-best-deal{
    padding: 80px 40px;
  }
  .reward-best-deal-left{
    width: 350px;
  }
}

</style>
